<template>
	<div class="edit-form-container attribute-form">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Алиас атрибута" prop="alias">
						<el-input v-model="formData.alias" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Название атрибута (ru)" prop="title_ru">
						<el-input v-model="formData.title_ru" />
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Назва атрибута (he)" prop="title_uk">
						<el-input v-model="formData.title_uk" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Группа атрибутов" prop="attribute_group_id">
						<SimpleSpinner :active="attributeGroupsLoading" small />
						<el-select
							v-model="formData.attribute_group_id"
							placeholder="выберите группу"
							:disabled="!attributeGroupsList.length"
						>
							<el-option
								v-for="item in attributeGroupsList"
								:key="'attribute_group_id-' + item.id"
								:label="item.title_ru"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>
				</div>
			</div>

			<!-- ------------- -->
			<div class="formRow options-container">
				<div class="el-form-item__label">Варианты</div>
				<div class="mcol-xs-12 mcol-sm-6">
					<div v-if="optionsList.length" class="content-row">
						<OptionItem
							v-for="(item, idx) in optionsList"
							ref="OptionItem"
							:key="`option_item-${item.id}`"
							:item-data="item"
							:item-index="idx"
							@onRemove="id => removeFormItem(id, 'optionsList')"
							@ready="blockReady"
						/>
					</div>

					<div class="content-row option-item-container">
						<el-button
							class="action-button create-button"
							size="mini"
							type="success"
							icon="icomoon icon-cross"
							@click="addFormItem('optionsList', 'opt_i-')"
						/>
					</div>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import {
	itemFormMixin,
	itemsFetchSetMixin,
	refsOperationsMixin,
	createFormItemMixin
} from '@/mixins';
import { updateFormData } from '@/helpers';

export default {
	components: {
		OptionItem: () => import('../../components/OptionItem.vue')
	},
	mixins: [
		itemFormMixin,
		itemsFetchSetMixin,
		refsOperationsMixin,
		createFormItemMixin
	],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,
			optionsList: [],

			formData: {
				alias: '',
				title_uk: '',
				title_ru: '',
				category_id: null,
				attribute_group_id: null,
				attribute_options: []
			}
		};
	},

	computed: {
		...mapState({
			attributeGroupsList: state => state.attribute_groups.itemsList,
			attributeGroupsLoading: state => state.attribute_groups.isLoading

			// categoriesList: (state) => state.categories.itemsList,
			// categoriesLoading: (state) => state.categories.isLoading
		}),

		refsList: () => ['OptionItem'],
		refsOperationsSettings: () => ({
			submitActionName: 'submitForm',
			dataAsArray: true,
			itemSubmitMethod: 'validateItemForm'
		}),

		rules: () => ({
			// alias: required,
			title_uk: required,
			title_ru: required,
			category_id: required,
			attribute_group_id: required
		}),

		translitSettings: () => ({
			formFields: [{ prop: 'alias', sourceProp: 'title_ru', ifIsEmpty: true }]
		}),

		initialSetFetchSettings: () => [
			{
				action: 'fetch_attribute_groups',
				payload: { params: { max: -1 } },
				clean_up: 'set_attribute_groups'
			}
		]
	},

	methods: {
		...mapActions({
			fetch_attribute_groups: 'attribute_groups/fetch_attribute_groups',
			set_attribute_groups: 'attribute_groups/set_attribute_groups'
		}),

		setupForm(itemData, formData) {
			// console.log(itemData, formData)
			// this.optionsList = [...itemData.attribute_options];
			this.optionsList = this.setupFormSubItemsList(
				itemData.attribute_options,
				'opt_i'
			);
			return updateFormData(itemData, formData);
		}
	}
};
</script>
